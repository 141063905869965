export default defineNuxtPlugin((nuxtApp) => {
  const GTM_ID = "G-GEKKXRSZNS"; // Replace with your GTM ID

  // Insert GTM script
  const insertGtm = () => {
    const script = document.createElement("script");
    script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `;
    document.head.appendChild(script);
  };

  // Insert GTM noscript
  const insertNoscript = () => {
    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  };

  // Add dataLayer
  window.dataLayer = window.dataLayer || [];

  // Initialize GTM
  if (process.client) {
    insertGtm();
    insertNoscript();
  }

  // Helper function to push events to dataLayer
  const pushEvent = (event) => {
    if (process.client) {
      window.dataLayer.push(event);
    }
  };

  // Make pushEvent available throughout the app
  return {
    provide: {
      gtm: {
        pushEvent,
      },
    },
  };
});
